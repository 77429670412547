import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './legal_documents.module.scss';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes, legalDocuments} from "../components/shared/routes";
import DocPdfIco from "../images/icons/document_pdf.svg";
import DeeplaiLogo from "../images/logos/deeplai-company-logo-white.svg";
import PageTitle from "../components/shared/PageTitle/PageTitle";

const LegalDocumentsPage = () => {
    const pageTitle = getRoutes().LegalDocuments.pageTitle;

    const data = [{
        logo: DeeplaiLogo,
        logoAlt: '',
        files: [{
            name: "Privacy Policy",
            url: legalDocuments.deeplai.privacyPolicy
        }]
    }];

    const renderAppFiles = (files = []) => {
        return files.map(file => {
            const {name, url} = file;
            return (
                <a href={url} target="_blank" key={`legal_documents_${url}`}>
                    <div className={css.linkWrapper}>
                        <div className={css.docLogo}>
                            <img src={DocPdfIco} alt=""/>
                        </div>
                        <div className={css.docText}>
                            {name}
                        </div>
                    </div>
                </a>
            );
        });
    };

    const renderApps = () => {

        return data.map((app, index) => {
            const {logo, logoAlt, files = []} = app;
            return (
                <div className={css.app} key={`legal_documents_app_${index}`}>
                    <div className={css.logoWrapper}>
                        <img src={logo} alt={logoAlt}/>
                    </div>
                    <div className={css.linksWrapper}>
                        {renderAppFiles(files)}
                    </div>
                </div>
            );
        });
    };

    return (
        <Layout>
            <SEO title={pageTitle} description={'Privacy policy T&C'}/>

            <PageTitle>{pageTitle}</PageTitle>

            <div className={css.wrapper}>

                <div className={css.wrapper}>
                    <div className={css.apps}>
                        <div className={css.appsInner}>{renderApps()}</div>
                    </div>
                </div>

                <Footer/>

            </div>
        </Layout>
    );
};

export default LegalDocumentsPage;

